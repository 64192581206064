import axios from 'axios'

// 配置全局变量
axios.defaults.baseURL = process.env.VUE_APP_BASE_API;


//拦截器
axios.interceptors.request.use(request => {
    request.headers['Content-Type'] = 'application/json;charset=utf-8';
    if (localStorage.getItem('token')) {
        request.headers.Authorization = localStorage.getItem('token')
    }

    return request
}, error => {
    return Promise.reject(error)
})
axios.interceptors.response.use(response => {
    return response.data
}, error => {
    return Promise.reject(error)
})

export function post(url, data) {
    return new Promise((resolve, reject) => {
        axios.post(url, data).then(res => {
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
}


export function get(url, params) {
    return new Promise((resolve, reject) => {
        axios.get(url, {
            params: params
        }).then(res => {
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
}

export default axios