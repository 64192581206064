<template>
  <!-- 分页 -->
  <div class="block">
    <el-pagination
      background
      layout="prev, pager, next,jumper"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page.sync="currentPage"
      :page-size="limit"
      :total="total"
    >
    </el-pagination>
    <div class="page">共 {{ num }} 页 第{{ currentPage }}页</div>
  </div>
</template>
<script>
export default {
  props: ["total", "num"],
  data() {
    return {
      currentPage: 1, //当前页数
      limit: 10,
    };
  },
  methods: {
    // 当前页
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    // 前进  后退  二合一  val-1 ==page
    handleCurrentChange(val) {
      this.$emit("page-limit", { page: val , limit: this.limit });
      console.log(`当前页: ${val}`);
    },
  },
};
</script>

<style lang="scss" scoped>
.block {
  margin-top: 100px;
  display: flex;
  align-items: center;
  font-size: 14px;
  justify-content: flex-end;
  padding: 0 20px 10px 0;

  .page {
    margin-bottom: 2px;
  }
}
</style>