import Vue from 'vue'

import Vuex from 'vuex'

Vue.use( Vuex )

export default new Vuex.Store( {
  state: {
 
 
  // 存储token
    Authorization: localStorage.getItem('Authorization') ? localStorage.getItem('Authorization') : ''
  
  },
  getters:{

  },
  mutations: {
    
    ChangeLogin(state,token){
    state.Authorization = token
    localStorage.setItem('token',token)
    },
    LogoOut(state,token){
      state.Authorization =  ""

    }
  },
  actions: {},
  modules: {}
} )
