import Vue from 'vue';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import ViewUI from 'view-design';
import 'view-design/dist/styles/iview.css';
import el_pagination  from  "@/components/el_pagination"
import App from './App.vue';
import store from './store';
import router from './router/index.js';
import { post, get } from "./utils/request";
import dayjs from 'dayjs'
import VueI18n from 'vue-i18n';
import { messages } from './components/common/i18n.js';
import { formatSeconds } from './utils/tools.js';
import 'babel-polyfill';
import './assets/css/main.css';
import './assets/css/theme.scss'   

Vue.config.productionTip = false
Vue.component('el_pagination',el_pagination)
Vue.use(ElementUI);
Vue.use(ViewUI);
Vue.use(VueI18n);
const i18n = new VueI18n({
  locale: 'zh',
  messages,

});

// // 全局挂载
Vue.prototype.$get = get
Vue.prototype.$post = post //请求
import * as echarts from 'echarts'; // 引入Echart
Vue.prototype.$echarts = echarts //挂载Echarts     
Vue.prototype.$formatSeconds = formatSeconds; // 全局使用该工具函数


Vue.config.productionTip = false;

Vue.prototype.$dayjs = dayjs 


// import vueConfig from '../vue.config'

router.beforeEach((to, from, next) => {
  document.title = `${to.meta.title} | 后台管理系统`;
  const token = localStorage.getItem('token');
  if (!token && to.path !== '/Login') {
    next('/Login');
  } else if (to.meta.permission) {  // 如果是管理员权限则可进入，这里只是简单的模拟管理员权限而已
    token ==  localStorage.getItem('token') ? next() : next('/403');
  } else {
    // 简单的判断IE10及以下，该组件不兼容
    if (navigator.userAgent.indexOf('MSIE') > -1) {
      Vue.prototype.$alert('vue-quill-editor组件不兼容IE10及以下浏览器，请使用更高版本的浏览器查看', '浏览器不兼容通知', {
        confirmButtonText: '确定'
      });
    } else {
      next();
    }
  }
})
new Vue( {
  router,
  store,
  i18n,
  render: h => h( App )
} ).$mount( '#app' )


