import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {



  return originalPush.call(this, location).catch(err => err);
}

const routes = [
  {
    path: '/',  // 程序启动默认路由
    component: () => import('@/components/common/Whole.vue'),
    meta: { title: '整体页面布局' },
    redirect: '/Home',  // 重定向到首页
    children: [
      {
        path: '/Home',
        component: () => import('@/page/Home.vue'),
        meta: { title: '首页' }
      },
      {
        path: '/Data',
        component: () => import('@/page/data/index.vue'),
        meta: { title: '文档查询' },
        redirect: '/Data/Document',  // 该配置是若点击选择一级菜单时，默认选中并跳转到该一级菜单下的第一个二级菜单
        children: [
          {
            path: 'Document',
            component: () => import('@/page/data/document.vue'),
            meta: { title: '文档查询' },
          },
          {
            path: 'Journal',
            component: () => import('@/page/data/Journal.vue'),
            meta: { title: '登录日志' },
          },
          {
            path: 'BOM',
            component: () => import('@/page/data/bom.vue'),
            meta: { title: 'BOM表管理' },
          },
          {
            path: 'productManagement',
            component: () => import('@/page/data/fileMangenmant/index.vue'),
            meta: { title: '归档管理' },
          },
          {
            path: 'productionManagement',
            component: () => import('@/page/data/fileMangenmant/productManagement.vue'),
            meta: { title: '产品管理' },
          },
          {
            path: 'productInformation',
            component: () => import('@/page/data/fileMangenmant/productInformation.vue'),
            meta: { title: '产品资料' },
          },
          {
            path: 'contract',
            component: () => import('@/page/data/contract.vue'),
            meta: { title: '合同管理' },
          }
        ]
      }, {
        path: '/CollaborativeManagement',
        component: () => import('@/page/CollaborativeManagement/index.vue'),
        meta: { title: '协同管理' },
        redirect:'/CollaborativeManagement/entrustedDesign',
        children: [

          {
            path: 'entrustedDesign',
            component: () => import('@/page/CollaborativeManagement/CollaborativeDesign/entrustedDesign.vue'),
            meta: { title: '委托设计' },
          },
          {
            path: 'dataTransmission',
            component: () => import('@/page/CollaborativeManagement/CollaborativeDesign/dataTransmission.vue'),
            meta: { title: '文档资料传输' },

          },
       
          {
            path: 'CollaborativeManufacturing',
            component: () => import('@/page/CollaborativeManagement/CollaborativeManufacturing/index.vue'),
            meta: { title: '协同制造' },
          }, {
            path: 'productionScheduleManagement',
            component: () => import('@/page/CollaborativeManagement/CollaborativeManufacturing/productionScheduleManagement.vue'),
            meta: { title: '生产进度管理' },

          },
          {
            path: 'manufacturingRelease',
            component: () => import('@/page/CollaborativeManagement/CollaborativeManufacturing/manufacturingRelease.vue'),
            meta: { title: '制造需求发布' },

          },
          {
            path: 'demandPush',
            component: () => import('@/page/CollaborativeManagement/CollaborativeManufacturing/demandPush.vue'),
            meta: { title: '制造需求推送' },

          },
          {
            path: 'CollaborativeServices',
            component: () => import('@/page/CollaborativeManagement/CollaborativeServices/index.vue'),
            meta: { title: '协同服务' },
          },
          {
            path: 'operationMaintenanceScheme',
            component: () => import('@/page/CollaborativeManagement/CollaborativeServices/operationMaintenanceScheme.vue'),
            meta: { title: '运维方案' },

          },
          {
            path: 'video',
            component: () => import('@/page/CollaborativeManagement/CollaborativeManufacturing/index.vue'),
            meta: { title: '视频会议' },
          },
          {
            path: 'manufacturingForum',
            component: () => import('@/page/CollaborativeManagement/CollaborativeServices/manufacturingForum.vue'),
            meta: { title: '制造业论坛' },

          },
          {
            path: 'supplyCoordination',
            component: () => import('@/page/CollaborativeManagement/supplyCoordination/index.vue'),
            meta: { title: '供应链协同' },

          },
          {
            path: 'procurementTask',
            component: () => import('@/page/CollaborativeManagement/supplyCoordination/procurementTask.vue'),
            meta: { title: '采购任务' },

          },
          {
            path: 'orderManagement',
            component: () => import('@/page/CollaborativeManagement/supplyCoordination/orderManagement.vue'),
            meta: { title: '收发货管理' },

          },
          {
            path: 'evaluationManagement',
            component: () => import('@/page/CollaborativeManagement/supplyCoordination/evaluationManagement.vue'),
            meta: { title: '合同评价' },

          },
        ]

      },
      {
        path: '/User',
        component: () => import('@/page/user/index.vue'),
        meta: { title: '用户信息管理' },
        redirect: 'User/userRegister',
        children: [
          {
            path: 'userRegister',
            component: () => import('@/page/user/userRegister.vue'),
            meta: { title: '用户新增' },
          },
          {
            path: 'userInfo',
            component: () => import('@/page/user/userInfo.vue'),
            meta: { title: '用户信息管理' },
          }
        ]

      },
      {
        path: '/404',
        component: () => import('@/page/404.vue'),
        meta: { title: '404' }
      },
      {
        path: '/403',
        component: () => import('@/page/403.vue'),
        meta: { title: '403' }
      },
    ]
  },
  {
    path: '/Login',  // 登录页面
    component: () => import('@/page/Login.vue'),
    meta: { title: '登录' }
  },
  {
    path: '*',
    redirect: '/404'
  }
]




const router = new VueRouter({
  routes
})


export default router
